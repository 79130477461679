import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable, Subject } from "rxjs";
import { VPNPassword } from "../../interfaces/vpn-password";
import { RunningExercise } from "../../types/running-exercise";
import { Guid } from "../../types/guid";
import { MAIN_CONTAINER_SIZES } from "src/app/config/containter-sizes";
import { ParsedContainerSize } from "../../types/container-size";
import { Announcement } from "../../interfaces/announcement";

@Injectable({
  providedIn: "root",
})
export class UIService {
  constructor(private apiService: ApiService) {}

  notificationsPanelToggle$: Subject<void> = new Subject();

  connectToLab(): Observable<any> {
    return this.apiService.getRequest("vpn/", { responseType: "text" });
  }

  getVPNPassword(): Observable<VPNPassword> {
    return this.apiService.getRequest<VPNPassword>("vpn/password/");
  }

  languageSessionKey(data: { language: string }): Observable<any> {
    return this.apiService.putRequest("me/language_session_key/", data);
  }

  getRunningExercise(): Observable<RunningExercise> {
    return this.apiService.getRequest<RunningExercise>("running_exercise/");
  }

  extendRunningExercise(exerciseId: Guid): Observable<{}> {
    return this.apiService.putRequest<{}>(
      `running_exercise/${exerciseId}/extend/`,
      {}
    );
  }

  getAnnouncements(): Observable<Array<Announcement>> {
    return this.apiService.getRequest<Array<Announcement>>(`announcements/`);
  }

  static setCSSContainers(): void {
    Object.entries(MAIN_CONTAINER_SIZES).forEach(([name, size]) => {
      document.documentElement.style.setProperty(`--${name}`, size);
    });
  }

  static parseContainers(): ParsedContainerSize {
    return Object.entries(MAIN_CONTAINER_SIZES).reduce((acc, [name, size]) => {
      return { ...acc, [name]: Number.parseInt(size) };
    }, {});
  }
}
