<div class="d-flex">
  <round-button
    class="hprimary-700"
    icon="chart-line"
    [disabled]="isDisabled('stats')"
    [link]="'/teacher/courses/' + courseId + '/groups/' + group?.id + '/stats'"
    [pure]="true"
  />

  <round-button
    class="hprimary-700"
    icon="trophy"
    set="uil"
    [disabled]="isDisabled('ranking') || !group.has_ranking"
    [link]="'/' + (isTeacher ? 'teacher' : 'student') + '/ranking'"
    [params]="{ filter_by: 'coursegroup', filter_pk: group?.id }"
    [pure]="true"
  />

  <round-button
    class="hprimary-700"
    icon="edit"
    [disabled]="isDisabled('edit')"
    [link]="'/teacher/groups/edit/' + group?.id"
    [pure]="true"
  />
</div>
