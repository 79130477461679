import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { interval, Observable, Subscription } from "rxjs";
import { DropdownMenuComponent } from "src/app/shared/blocks/dropdown-menu/dropdown-menu.component";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromUI from "src/app/store/actions/ui.actions";
import {
  NotificationSettings,
  NotificationSnooze,
} from "src/app/shared/types/notification-settings";
import { NotificationsService } from "src/app/shared/services/notifications/notifications.service";
import { map, take, tap, withLatestFrom } from "rxjs/operators";
import * as moment from "moment";
import { Guid } from "src/app/shared/types/guid";
import { WebSocketMessageType } from "src/app/shared/types/websocket-message-type";
import { UIService } from "src/app/shared/services/ui/ui.service";

@Component({
  selector: "notifications-icon",
  templateUrl: "./notifications-icon.component.html",
  styleUrls: ["./notifications-icon.component.scss"],
})
export class NotificationsIconComponent
  extends DropdownMenuComponent
  implements OnInit, OnDestroy
{
  unreadNotificationsCount$: Observable<number> = this.store.select(
    (state) => state.ui.unreadNotificationsCount,
  );

  notificationSettings$: Observable<NotificationSettings> = this.store
    .select((state) => state.ui.notificationSettings)
    .pipe(
      withLatestFrom(
        this.store.select((state) => state.account.member.is_teacher),
      ),
      map(([settings, isTeacher]: [NotificationSettings | null, boolean]) => {
        if (!settings) {
          return this.notificationsService.createSettings(
            isTeacher ? "teacher" : "student",
          );
        } else {
          return settings;
        }
      }),
      tap((settings) => {
        let settingsMap = <{ [key in WebSocketMessageType]: boolean }>(
          settings.get("notificationTypeSettings").toJS()
        );
        this.isAllTurnedOff = !Object.values(settingsMap).reduce(
          (acc: boolean, value: boolean) => {
            return acc || value;
          },
          false,
        );
        this.snoozeDate = settings.get("notificationSnooze").get("date");
        this.snoozePeriod = settings.get("notificationSnooze").get("period");
        this.isSnoozed =
          (this.snoozeDate && Number.isInteger(<number>this.snoozePeriod)) ||
          this.snoozePeriod === "always";
      }),
    );

  snoozeTimer: Subscription;
  toggleSubscription: Subscription;

  isActive = false;
  isSnoozed = false;
  isAllTurnedOff = false;

  private snoozePeriod: NotificationSnooze["period"];
  private snoozeDate: string;
  private userId: Guid;

  constructor(
    private store: Store<{
      ui: fromUIStore.UIState;
      account: fromAccountStore.AccountState;
    }>,
    private notificationsService: NotificationsService,
    private uiService: UIService,
    private _ref: ElementRef,
  ) {
    super(_ref);
  }

  ngOnInit(): void {
    this.snoozeTimer = interval(1000)
      .pipe(
        withLatestFrom(
          this.notificationSettings$,
          this.store.select((state) => state.account.member.id),
        ),
      )
      .subscribe(
        ([tick, settings, userId]: [number, NotificationSettings, Guid]) => {
          // Assign userId
          this.userId = userId;

          // Reset snooze automatically
          if (
            this.snoozeDate &&
            Number.isInteger(<number>this.snoozePeriod) &&
            moment().isSameOrAfter(moment(this.snoozeDate))
          ) {
            const snooze: NotificationSnooze = { period: undefined };
            const updatedSettings = settings.mergeIn(
              ["notificationSnooze"],
              snooze,
            );
            this.store.dispatch(
              new fromUI.SaveNotificationSettings({
                settings: updatedSettings,
                userId,
              }),
            );
          }
        },
      );

    this.toggleSubscription =
      this.uiService.notificationsPanelToggle$.subscribe(() => {
        this.isActive = true;
      });
  }

  ngOnDestroy(): void {
    this.snoozeTimer.unsubscribe();
    this.toggleSubscription.unsubscribe();
  }

  closePanel(): void {
    this.isActive = false;
  }

  @HostListener("document:visibilitychange", ["$event"])
  @HostListener("window:focus", ["$event"])
  onVisibilityChange(event) {
    this.store
      .select((state) => state.account.member.is_teacher)
      .pipe(take(1))
      .subscribe((isTeacher) => {
        if (
          event.type === "focus" ||
          (event.type === "visibilitychange" &&
            document.visibilityState === "visible")
        ) {
          this.store.dispatch(
            new fromUI.LoadNotificationSettings({
              accessMember: isTeacher ? "teacher" : "student",
              userId: this.userId,
            }),
          );
        }
      });
  }
}
