import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap, catchError, map } from "rxjs/operators";
import {
  organizationsListLoad,
  organizationsListLoadSuccess,
  organizationsListLoadFail,
} from "../actions/organizations.actions";
import { OrganizationsService } from "src/app/shared/services/organizations/organizations.service";
import { Organization } from "src/app/shared/interfaces/organization";

@Injectable()
export class OrganizationsEffect {
  getCoursesAdminList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsListLoad),
      switchMap(() => {
        return this.organizationsService.getOrganizations().pipe(
          map((res: Organization[]) =>
            organizationsListLoadSuccess({ organizations: res }),
          ),
          catchError(({ error }) => of(organizationsListLoadFail(error))),
        );
      }),
    ),
  );

  constructor(
    private organizationsService: OrganizationsService,
    private actions$: Actions,
  ) {}
}
