<ng-container *ngIf="isTeacher">
  <a routerLink="/ide" class="dashboard-menu-item-link">
    <simple-dashboard-widget
      [hover]
      [data]="{
        sub_label: 'COURSES.DASHBOARD.PLACES_MENU.CODE_EDITOR'
      }"
      icon="code"
    >
    </simple-dashboard-widget>
  </a>
</ng-container>

<a
  routerLink="../courses/onboarding"
  class="dashboard-menu-item-link"
  (click)="setFirstStepsDescVisibility('hidden')"
>
  <simple-dashboard-widget
    [hover]
    [data]="{
      sub_label: 'COURSES.DASHBOARD.PLACES_MENU.ONBOARDING'
    }"
    icon="rocket"
    set="uil"
  >
  </simple-dashboard-widget>
</a>

<div *ngIf="firstStepsDescVisible" class="onboarding-first-steps">
  <p
    class="first-steps-desc"
    [innerHTML]="'COURSES.DASHBOARD.PLACES_MENU.ONBOARDING_DESC' | translate"
  ></p>
</div>
