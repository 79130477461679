import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Organization } from "src/app/shared/interfaces/organization";

export const organizationsPrefix = "[Organizations List]";

const organizationsListActions = {
  request: `${organizationsPrefix} Load`,
  requestSuccess: `${organizationsPrefix} Load success`,
  requestFail: `${organizationsPrefix} Load fail`,
};

export const organizationsListLoad = createAction(
  organizationsListActions.request,
);

export const organizationsListLoadSuccess = createAction(
  organizationsListActions.requestSuccess,
  props<{ organizations: Organization[] }>(),
);

export const organizationsListLoadFail = createAction(
  organizationsListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
