import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ExerciseStatus } from "../../enums/exercise-status";
import { isNil } from "lodash-es";
import { KeyIconComponent } from "../key-icon/key-icon.component";

@Component({
  selector: "exercise-status",
  templateUrl: "exercise-status.component.html",
  styleUrls: ["./exercise-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExerciseStatusComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() status = ExerciseStatus.NEW;

  @ViewChild("statusIcon", { read: ViewContainerRef })
  private readonly iconContainer: ViewContainerRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { status } = changes;

    if (!isNil(status) && !status.firstChange && this.iconContainer) {
      this.createIconComponent(status.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.iconContainer.clear();
    this.iconContainer.detach();
  }

  ngAfterViewInit(): void {
    this.createIconComponent(this.status);
  }

  private createIconComponent(status: ExerciseStatus): void {
    this.iconContainer.clear();

    const iconComponent = this.iconContainer.createComponent(KeyIconComponent);
    const { instance } = iconComponent;

    instance.size = "xs";

    switch (status) {
      case ExerciseStatus.NEW:
      case ExerciseStatus.UNCOMPLETED:
        instance.kind = "gray";
        instance.theme = "light";
        instance.icon = "check";
        break;

      case ExerciseStatus.COMPLETED:
        instance.icon = "check";
        break;

      case ExerciseStatus.FAILED:
        instance.kind = "error"
        instance.icon = "cancel-outline";
        instance.set = "typcn";
        break;

      case ExerciseStatus.STARTED:
        instance.kind = "warning";
        instance.theme = "light";
        instance.icon = "stopwatch";
        break;

      case ExerciseStatus.INDIVIDUAL:
        instance.kind = "gray";
        instance.theme = "light";
        instance.icon = "plus-circle";
    }

    this.cd.detectChanges();
  }
}
