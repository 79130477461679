import { Component, Input, Type } from "@angular/core";
import { ModalComponent } from "../../components/modal/modal.component";
import { VpnConfigurationModalComponent } from "../../components/modals/vpn-configuration-modal/vpn-configuration-modal.component";
import { NavbarElementComponent } from "../../components/navbar/navbar-element/navbar-element.component";
import { Store } from "@ngrx/store";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";

@Component({
  selector: "avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent extends NavbarElementComponent {
  @Input() name = "";
  @Input() isAdmin = false;
  @Input("remoteIP") remote_ip;

  vpnModal: Type<ModalComponent> = VpnConfigurationModalComponent;

  constructor(
    private store: Store<{
      account: fromAccountStore.AccountState;
    }>,
  ) {
    super();
  }

  hideMainMenu(event: MouseEvent) {
    event.preventDefault();
    super.toggle();
  }
}
