<span
  class="label badge d-flex align-items-center flex-row{{
    reversed ? '-reverse' : ''
  }} theme-{{ theme }}"
>
  <span *ngIf="icon" class="badge-icon {{ set }} {{ set }}-{{ icon }}"></span>

  <span>
    <ng-content></ng-content>
  </span>
</span>
