import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { HelpWidgetService } from "./shared/services/help-widget/help-widget.service";
import { environment } from "src/environments/environment";
import { UIService } from "./shared/services/ui/ui.service";
import videojs from "video.js";

import * as videojsPL from 'video.js/dist/lang/pl.json';

@Component({
  selector: "root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "CyberSkiller";

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private helpWidget: HelpWidgetService
  ) {
    translate.setDefaultLang(environment.defaultLang);
    this.getCookiesLang();
    this.helpWidget.destroyWidget();
    this.helpWidget.bootWidget();
    this.helpWidget.hideWidget();
    UIService.setCSSContainers();
    videojs.addLanguage("pl", {...videojsPL, "Quality": "Jakość"});
  }

  private getCookiesLang() {
    let lang: string = this.cookieService.get("cyber-skiller-lang");

    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use(environment.defaultLang);
    }
  }
}
