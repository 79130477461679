import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CookieService } from "ngx-cookie-service";
import { EMPTY, of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { WelcomeScreenModalComponent } from "src/app/shared/components/modals/welcome-screen-modal/welcome-screen-modal.component";
import { ConfigStorageService } from "src/app/shared/services/config-storage/config-storage.service";
import { HelpWidgetService } from "src/app/shared/services/help-widget/help-widget.service";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { AuthToken } from "../../shared/interfaces/auth-token";
import { AuthService } from "../../shared/services/auth/auth.service";
import * as fromAccount from "../actions/account.actions";
import * as fromAuth from "../actions/auth.actions";
import * as fromUI from "../actions/ui.actions";

@Injectable()
export class AuthEffect {
  login$ = createEffect(() => this.actions$.pipe(
    ofType(fromAuth.LOGIN),
    mergeMap((action: fromAuth.Login) =>
      this.authService.login(action.payload).pipe(
        map((authToken: AuthToken) => {
          this.authService.saveToStorage(authToken);
          return {
            type: fromAuth.LOGIN_COMPLETED,
            payload: {
              authToken: authToken,
            },
          };
        }),
        catchError((httpError) => {
          return of({
            type: fromAuth.LOGIN_FAILED,
            payload: httpError.error,
          });
        })
      )
    )
  ));

  readToken$ = createEffect(() => this.actions$.pipe(
    ofType(fromAuth.READ_TOKEN),
    mergeMap(() =>
      this.authService.getFromStorage().pipe(
        map((authToken: AuthToken) => {
          if (authToken) {
            return {
              type: fromAuth.LOGIN_COMPLETED,
              payload: { authToken: authToken },
            };
          } else {
            return {
              type: fromAuth.READ_TOKEN_FAILED,
            };
          }
        }),
        catchError(() => EMPTY)
      )
    )
  ));

  loginCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(fromAuth.LOGIN_COMPLETED),
    mergeMap((action: fromAuth.LoginCompleted) => {
      this.http
        .get<{ [key: string]: any }>("config/config.json")
        .subscribe((config) => {
          this.configStorage.store(config);

          if (
            !this.cookieService.check("cyber-skiller-skip-welcome") &&
            config.showWelcomeScreen
          ) {
            this.modalService.showModal(
              { modalTitle: "GLOBAL.WELCOME_SCREEN.TITLE" },
              WelcomeScreenModalComponent,
              "WELCOME_SCREEN_MODAL"
            );
          }
        });

      return [
        {
          type: fromAccount.GET_MEMBER,
        },
        {
          type: fromUI.INIT_WEBSOCKET,
          payload: action.payload.authToken,
        },
        {
          type: fromUI.GET_RUNNING_EXERCISE,
        },
      ];
    }),
    catchError(() => EMPTY)
  ));

  readTokenFailed$ = createEffect(() => this.actions$.pipe(
    ofType(fromAuth.READ_TOKEN_FAILED),
    switchMap(() => {
      return of({
        type: fromAuth.LOGOUT,
      });
    }),
    catchError(() => EMPTY)
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(fromAuth.LOGOUT),
    switchMap(() => {
      if (this.helpWidget.isMounted()) {
        this.helpWidget.hideWidget();
      }

      this.authService.logout();
      return of({});
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private modalService: ModalService,
    private cookieService: CookieService,
    private helpWidget: HelpWidgetService,
    private configStorage: ConfigStorageService,
    private http: HttpClient
  ) {}
}
