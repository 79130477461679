import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { OrderByAvailabilityPipe } from "src/app/shared/pipes/order-by-availability.pipe";
import { Guid } from "src/app/shared/types/guid";
import { AnswerPayload } from "../../../interfaces/answer-payload";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { ExerciseSelectionParams } from "../../../interfaces/exercise-selection";
import * as fromCourse from "../../../../store/actions/course.actions";
import { Store } from "@ngrx/store";
import { CourseSelectorsState } from "../../../../store/selectors/course.selectors";
import { Topic } from "../../../interfaces/topic";
import { Observable } from "rxjs";
import { Nillable } from "../../../types/nillable";
import { ExerciseSection } from "../../../enums/exercise-section";
import { isEmpty } from "lodash-es";

@Component({
  selector: "course-topic-exercises",
  templateUrl: "./course-topic-exercises.component.html",
  styleUrls: ["./course-topic-exercises.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseTopicExercisesComponent implements OnChanges {
  @Input() headerLabel: string = "COURSES.COURSE.EXERCISES";
  @Input() section: Nillable<ExerciseSection>;
  @Input() subtitleLabel: Nillable<string>;
  @Input() currentTopic: Topic;
  @Input() exercises: Exercise[] = [];
  @Input() courseId: Guid;
  @Input() groupId: Guid;
  @Input("teacher") isTeacher = false;

  @Input()
  emitters: {
    [key: string]: EventEmitter<ExerciseControl | HelpRequest | AnswerPayload>;
  };

  order: Record<Guid, number> = {};

  bulkInProgress$: Observable<ExerciseSelectionParams> = this.store.select(
    (state) =>
      state[this.isTeacher ? "teacher" : "student"].singleCourse.currentTopic
        .bulkInProgress || {},
  );

  constructor(
    private readonly store: Store<CourseSelectorsState>,
    private readonly orderByAvailability: OrderByAvailabilityPipe,
  ) {}

  get isEmpty(): boolean {
    return isEmpty(this.exercises);
  }

  get allIncluded(): boolean {
    return (
      this.exercises.length &&
      this.exercises.every((exercise) => exercise.available)
    );
  }

  get allHelpTurnedOn(): boolean {
    return (
      this.exercises.filter((exercise) => exercise.available_help).length &&
      this.exercises
        .filter((exercise) => exercise.available_help)
        .every((exercise) => exercise.has_help)
    );
  }

  get allVideoTurnedOn(): boolean {
    return (
      this.exercises.filter((exercise) => exercise.available_video).length &&
      this.exercises
        .filter((exercise) => exercise.available_video)
        .every((exercise) => exercise.has_video)
    );
  }

  get anyHasHelp(): boolean {
    return this.exercises.some((exercise) => exercise.available_help);
  }

  get anyHasVideo(): boolean {
    return this.exercises.some((exercise) => exercise.available_video);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentTopic } = changes;

    if (currentTopic) {
      this.order = this.orderTransformedByAvailability();
    }
  }

  guid(_index: number, exercise: Exercise): Guid {
    return exercise.id;
  }

  selectUnselect(param: keyof ExerciseSelectionParams, value: boolean): void {
    this.store.dispatch(
      new fromCourse.SelectUnselectAll({
        selection: {
          CourseId: this.courseId,
          GroupId: this.groupId,
          TopicId: this.currentTopic.id,
        },
        selectionParam: { [param]: !value, section: this.section ?? null },
        selectionParamName: param,
      }),
    );
  }

  private orderTransformedByAvailability(): Record<Guid, number> {
    return this.orderByAvailability
      .transform(this.exercises.slice(), this.isTeacher)
      .reduce((order, exercise, index) => {
        return { ...order, [exercise.id]: index + 1 };
      }, {});
  }
}
