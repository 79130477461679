import { Component, Input, OnInit, Type } from "@angular/core";
import { Course } from "../../interfaces/course";
import { ModalComponent } from "../modal/modal.component";
import { VpnConfigurationModalComponent } from "../modals/vpn-configuration-modal/vpn-configuration-modal.component";

@Component({
  selector: "dashboard-menu",
  templateUrl: "./dashboard-menu.component.html",
  styleUrls: ["./dashboard-menu.component.scss"],
})
export class DashboardMenuComponent implements OnInit {
  @Input() isTeacher: boolean;

  vpnModal: Type<ModalComponent> = VpnConfigurationModalComponent;
  firstStepsDescVisible = false;

  private storageName = "cyber-skiller-first-steps-desc-visiblity";

  ngOnInit() {
    this.setFirstStepsDescVisibility(
      window.localStorage.getItem(this.storageName),
    );
  }

  setFirstStepsDescVisibility(visibility: string | null) {
    this.firstStepsDescVisible =
      visibility === null ? true : visibility === "hidden" ? false : true;

    if (visibility && !window.localStorage.getItem(this.storageName)) {
      window.localStorage.setItem(this.storageName, visibility);
    }
  }
}
