import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconSet } from "../../types/icon-set";
import { IconName } from "../../types/icon-name";

@Component({
  selector: "icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() set: IconSet = "typcn";
  @Input() name: IconName;
}
