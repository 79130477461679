import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'list-progress',
  templateUrl: './list-progress.component.html',
  styleUrls: ['./list-progress.component.scss']
})
export class ListProgressComponent implements OnInit {
  @Input()
  data: { completed: number; all: number };

  constructor() {}

  ngOnInit() {}

  calcPercentage(): { width: string } {
    return { width: (this.data.completed / this.data.all) * 100 + '%' };
  }
}
