<modal-overlay>
  <modal-box>
    <div class="row no-gutters flex-column">
      <div class="col-12">
        <h5 class="modal-title mb-3">{{ modalTitle | translate }}</h5>
        <span class="modal-subtitle">
          {{ "COURSES.COURSE.EXERCISE.UPLOAD_FILE_WITH_SOLUTION" | translate }}
        </span>
      </div>

      <div class="col-12 mt-4 mb-5">
        <div class="mb-4">
          <ng-container *ngIf="uploadConfig$ | async as config">
            <file-upload
              class="file-upload-secondary"
              [minFileSize]="1"
              [maxFileSize]="config.max_size ?? 0"
              [maxFiles]="config.max_files ?? 0"
              [extensions]="config.available_extensions ?? []"
              [customFileListTemplateRef]="listTemplateRef"
              (filesAdded)="setFileName($event)"
            />

            <ng-template #listTemplateRef let-files="files">
              <div class="mt-4">
                <file-list [files]="files" />
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div class="col-12">
        <div class="d-flex">
          <div class="flex-fill">
            <button
              class="theme-hprimary w-100 px-5 mr-3"
              (click)="handleCancelClick()"
            >
              {{ "GLOBAL.CANCEL_BUTTON" | translate }}
            </button>
          </div>

          <div class="flex-fill">
            <button
              class="theme-hprimarydark w-100 px-5 ml-3"
              [disabled]="isUploadDisabled"
              (click)="handleUploadFileClick()"
            >
              {{ "GLOBAL.NEXT" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal-box>
</modal-overlay>
