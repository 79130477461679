<div class="notifications-icon">
  <div
    [hover]
    class="notifications-icon-body"
    (click)="toggleMenu()"
    [ngClass]="{ active: isActive }"
  >
    <div class="icon d-flex align-items-center justify-content-center">
      <span
        class="uil"
        [ngClass]="{
          'uil-bell': !isSnoozed && !isAllTurnedOff,
          'uil-bell-slash': isSnoozed || isAllTurnedOff
        }"
      >
      </span>

      <span
        class="notifications-counter"
        *ngIf="unreadNotificationsCount$ | async"
      >
        {{ unreadNotificationsCount$ | async }}
      </span>
    </div>
  </div>

  <notifications-panel
    [ngStyle]="{ display: isActive ? 'block' : 'none' }"
    [active]="isActive"
    (onPanelNotify)="closePanel()"
  />
</div>
