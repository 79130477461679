import { Component, Input } from "@angular/core";

@Component({
  selector: "popup-trigger-label-with-chevron",
  templateUrl: "./popup-trigger-label-with-chevron.component.html",
  styleUrls: ["./popup-trigger-label-with-chevron.component.scss"],
})
export class PopupTriggerLabelWithChevronComponent {
  @Input() expanded = false;
  @Input() label = false;
}
