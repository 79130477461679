<div class="d-flex align-items-center">
  <ng-container
    *ngIf="isTeacher; then teacherTemplateRef; else studentTemplateRef"
  />
  <ng-template #teacherTemplateRef>
    <course-exercise-answer-menu
      [count]="count"
      (showModalEvent)="handleShowAnswerListModal()"
    />
  </ng-template>
  <ng-template #studentTemplateRef>
    <div class="mr-4">
      <course-exercise-answer-history-popup-menu
        [answers]="answers"
        (downloadEvent)="handleDownload($event)"
      />
    </div>

    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="ExerciseUploadStatus.Failed">
        <button
          type="button"
          class="exercise-file-upload-action exercise-file-upload-action-failed"
        >
          <span>
            {{ "COURSES.COURSE.EXERCISE.FAILED" | translate }}
          </span>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="ExerciseUploadStatus.Passed">
        <button
          type="button"
          class="exercise-file-upload-action exercise-file-upload-action-passed"
        >
          <span>
            {{ "COURSES.COURSE.EXERCISE.PASSED" | translate }}
          </span>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="ExerciseUploadStatus.Sent">
        <button
          type="button"
          class="exercise-file-upload-action exercise-file-upload-action-sent"
        >
          <span>
            {{ "COURSES.COURSE.EXERCISE.SENT" | translate }}
          </span>
        </button>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <button
          type="button"
          class="exercise-file-upload-action"
          [disabled]="isSendSolutionDisabled"
          (click)="handleSendSolutionClick()"
        >
          <span>
            {{ "COURSES.COURSE.EXERCISE.SUBMIT_SOLUTION" | translate }}
          </span>
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
