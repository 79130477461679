import { Component, Input } from "@angular/core";
import { Course } from "../../../interfaces/course";
import { CourseListGroup } from "../../../interfaces/group";
import { Store } from "@ngrx/store";
import * as fromGroups from "../../../../store/actions/groups.actions";

@Component({
  selector: "group-listbox-element-content",
  templateUrl: "./group-listbox-element-content.component.html",
  styleUrls: ["./group-listbox-element-content.component.scss"],
})
export class GroupListboxElementContentComponent {
  @Input() showProgressBadge = true;
  @Input("teacher") isTeacher = false;
  @Input() course: Course;
  @Input() group: CourseListGroup;
  @Input() showOrganization = false;
  @Input() showCourse = false;

  constructor(private readonly store: Store<{}>) {}

  archiveGroup() {
    this.store.dispatch(new fromGroups.ArchiveGroup(this.group.id));
  }
}
