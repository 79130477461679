<ul class="navbar-nav-list left">
  <ng-container *ngFor="let item of leftSideNavItems">
    <li class="navbar-nav-list-item">
      <navbar-nav-element
        (click)="hideMenu()"
        [outlined]="false"
        [label]="item.label | translate"
        [icon]="item.icon"
        [set]="item.iconSet"
        [route]="item.route"
      />
    </li>
  </ng-container>
</ul>

<ul class="navbar-nav-list right">
  <ng-container *ngFor="let item of rightSideNavItems">
    <li class="navbar-nav-list-item">
      <navbar-nav-element
        (click)="hideMenu()"
        [label]="item.label | translate"
        [icon]="item.icon"
        [set]="item.iconSet"
        [route]="item.route"
      />
    </li>
  </ng-container>
</ul>
