<div class="row align-items-center">
  <div class="col">
    <div class="row align-items-center">
      <div class="col-lg-12 col-xl">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-5">
            <ng-container *ngIf="isTeacher">
              <span class="heading-6">{{ group.name }}</span>
            </ng-container>

            <ng-container *ngIf="!isTeacher">
              <span class="heading-6">{{ course.name }}</span>
            </ng-container>
          </div>

          <ng-container *ngIf="!isTeacher">
            <div class="col-12 col-md">
              <group-teacher-name [name]="group.teacher_name" />
            </div>
          </ng-container>

          <ng-container *ngIf="!isTeacher">
            <div
              class="col-md-12 col-lg col-xl font-weight-bold text-uppercase text-xl-left"
            >
              <ng-container *ngIf="group.weekday !== null">
                {{ group.weekday | day | translate }} <br />
                {{ group.start_time | formatHour }} -
                {{ group.end_time | formatHour }}
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="isTeacher">
            <div
              class="mt-3 mt-lg-0 col-md-12 col-lg col-xl font-weight-bold text-uppercase"
            >
              <div class="row align-items-center justify-content-lg-center">
                <div class="col-auto">
                  <span class="label badge text-center">
                    <students-count-badge [count]="group.students_count" />
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="!isTeacher && group">
        <div class="mt-md-3 mt-lg-0 col-md col-lg-auto col-xl-3">
          <list-progress
            class="d-none d-md-block d-lg-none d-xl-block w-100"
            [data]="{
              completed: group.passed_exercises,
              all: group.all_exercises
            }"
          />
        </div>

        <ng-container *ngIf="showProgressBadge">
          <div class="mt-3 mt-lg-0 ml-lg-auto col-auto col-md-3 col-lg-2">
            <div class="row align-items-center justify-content-center">
              <div class="col-auto">
                <resolved-exercises-count-badge
                  [passed]="group.passed_exercises"
                  [all]="group.all_exercises"
                />
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div
        *ngIf="showOrganization"
        class="mt-md-3 mt-lg-0 col-md col-lg-auto col-xl-4"
      >
        <span class="heading-6">{{ group.organization | organizationName | async }}</span>
      </div>

      <div
        *ngIf="showCourse"
        class="mt-md-3 mt-lg-0 col-md col-lg-auto col-xl-4"
      >
        <span class="heading-6">{{ course.name }}</span>
      </div>
    </div>
  </div>

  <div class="col-5 col-md-3 col-lg-2 col-xl-2">
    <div class="d-flex justify-content-end">
      <div class="buttons d-flex align-items-center">
        <round-button
          icon="chart-line"
          class="hprimarydark"
          [tooltip]="isTeacher ? 'GROUPS.LIST.GROUP_STATS' : 'COURSES.COURSES.COURSE_STATS'"
          [link]="'../courses/' + course.id + '/groups/' + group.id + '/stats'"
          [pure]="true"
        />

        <round-button
          *ngIf="group.has_ranking"
          icon="trophy"
          set="uil"
          tooltip="GROUPS.LIST.GROUP_RANK"
          class="hprimarydark"
          [link]="'/' + (isTeacher ? 'teacher' : 'student') + '/ranking'"
          [params]="{ filter_by: 'coursegroup', filter_pk: group.id }"
          [pure]="true"
        />

        <ng-container *ngIf="isTeacher">
          <round-button
            icon="edit"
            tooltip="GROUPS.LIST.GROUP_EDIT"
            class="hprimarydark"
            [link]="'edit/' + group.id"
            [pure]="true"
          />

          <round-button
            confirmation-title="{{ group.name }}"
            confirmation="GROUPS.LIST.ARCHIVE_GROUP_MESSAGE"
            confirmation-icon="warning"
            icon="archive-alt"
            set="uil"
            tooltip="GROUPS.LIST.GROUP_ARCHIVE"
            class="hprimarydark"
            [pure]="true"
            (onConfirm)="archiveGroup()"
          />
        </ng-container>

        <round-button
          icon="chevron-right"
          tooltip="GROUPS.LIST.GROUP_GO"
          class="hprimarydark"
          [link]="'../courses/' + course.id + '/groups/' + group.id"
        />
      </div>
    </div>
  </div>
</div>
