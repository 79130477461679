import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "cs-footer",
  template: `
    <footer
      class="footer d-flex flex-column justify-content-center"
      *ngIf="year"
    >
      <ul class="paragraph-small footer-nav d-flex flex-wrap">
        <li>
          <a href="{{
            'GLOBAL.ENVIRONMENT.$langKey.TERMS' | environmentTranslate
          }}" target="_blank">{{
            "GLOBAL.FOOTER.TERMS" | translate
          }}</a>
        </li>
        <li>
          <a
            href="{{
              'GLOBAL.ENVIRONMENT.$langKey.PRIVACY_POLICY'
                | environmentTranslate
            }}"
            target="_blank"
            >{{
              "GLOBAL.ENVIRONMENT.$langKey.PRIVACY_POLICY_TEXT"
                | environmentTranslate
            }}</a
          >
        </li>
      </ul>
      <p class="paragraph-small">
        {{ "GLOBAL.FOOTER.TITLE" | translate: { year: year } }}
      </p>
    </footer>
  `,
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  year = 0;

  constructor() {}

  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}
