import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
})
export class DashboardLayoutComponent implements OnInit {
  expanded: boolean = false;
  private cookieName = "cs-sidebar-expanded";

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    if (this.cookieService.check(this.cookieName)) {
      this.expanded = this.cookieService.get(this.cookieName) === "true";
    }
  }

  setExpand(_expanded: boolean): void {
    this.expanded = _expanded;
    this.cookieService.set(
      this.cookieName,
      String(this.expanded),
      730,
      "/",
      undefined,
      undefined,
      "Strict"
    );
  }
}
