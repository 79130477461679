import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Exercise } from "../../../interfaces/exercise";

@Component({
  selector: "div[exercise-none-control-buttons]",
  templateUrl: "./exercise-none-control-buttons.component.html",
  styleUrls: ["./exercise-none-control-buttons.component.scss"],
})
export class ExerciseNoneControlButtonsComponent {
  @Input() exercise: Exercise;

  @Output() onMarkAsAnswered = new EventEmitter<MouseEvent>();

  markAsAnswered(event: MouseEvent): void {
    this.onMarkAsAnswered.emit(event);
  }
}
