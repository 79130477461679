import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { Topic } from "../../../interfaces/topic";
import { AnswerPayload } from "../../../interfaces/answer-payload";
import { Guid } from "src/app/shared/types/guid";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseSection } from "../../../enums/exercise-section";
import { isEmpty, isNil } from "lodash-es";

@Component({
  selector: "course-topic",
  templateUrl: "./course-topic.component.html",
  styleUrls: ["./course-topic.component.scss"],
})
export class CourseTopicComponent implements OnChanges {
  @Input() currentTopic: Topic;
  @Input() topics: Array<Topic>;
  @Input() courseId: Guid;
  @Input() topicId: Guid;
  @Input() activeGroup: Guid;
  @Input() individualSubtitleLabel!: string;

  @Output()
  startExercise: EventEmitter<ExerciseControl> =
    new EventEmitter<ExerciseControl>();
  @Output()
  stopExercise: EventEmitter<ExerciseControl> =
    new EventEmitter<ExerciseControl>();
  @Output()
  getHelp: EventEmitter<HelpRequest> = new EventEmitter<HelpRequest>();
  @Output()
  getVideoHelp: EventEmitter<HelpRequest> = new EventEmitter<HelpRequest>();

  @Output()
  answer: EventEmitter<AnswerPayload> = new EventEmitter<AnswerPayload>();

  @Input("teacher") isTeacher = false;

  exercises: Exercise[] = [];
  individualExercises: Exercise[] = [];

  readonly ExerciseSection = ExerciseSection;

  get isIndividualListEmpty(): boolean {
    return isEmpty(this.individualExercises);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentTopic } = changes;

    if (currentTopic) {
      this.exercises = this.prepareExercises();
      this.individualExercises = this.prepareIndividualExercises();
    }
  }

  private prepareExercises(): Exercise[] {
    const { exercises } = this.currentTopic;

    return exercises.filter((exercise) => isNil(exercise.section));
  }

  private prepareIndividualExercises(): Exercise[] {
    const { exercises } = this.currentTopic;

    return exercises.filter(
      (exercise) => exercise.section === ExerciseSection.INDIVIDUAL,
    );
  }
}
