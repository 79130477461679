<form [formGroup]="form">
  <div class="form-container">
    <div class="form-container__header" (click)="toggle()">
      <p class="form-container__title">
        {{ "COURSES.COURSE.SET_GRADES" | translate }}
      </p>
      <span
        class="typcn typcn-chevron-right chevron-toggled"
        [ngClass]="{
          toggled: isOn
        }"
      >
      </span>
    </div>

    <div
      *ngIf="isOn"
      class="form-container__content d-flex flex-column justify-content-center pt-4"
    >
      <hint class="w-100">
        {{ "COURSES.COURSE.RATING_SYSTEM_HINT" | translate }}
      </hint>
      <div class="pt-4">
        <select-button
          [options]="ratingOptions$ | async"
          [selected]="currentCriteriaType"
          (optionClickEvent)="handleShowRateOption($event)"
        />
      </div>
      <div class="pr-5 pl-5 pt-5">
        <div class="d-flex justify-content-center mb-4">
          <input-switch
            [label]="'COURSES.COURSE.HALF_MARKS' | translate | lowercase"
            [formControl]="form.controls.halfGradesVisible"
          />
        </div>
        <ng-container *ngIf="records$ | async as records">
          <div class="records-grid">
            <ng-container
              *ngFor="
                let record of records;
                let i = index;
                let last = last;
                trackBy: recordTrackBy
              "
            >
              <ng-container *ngIf="record.isVisible">
                <div class="form-grade-group" [ngClass]="getClass(i + 1)">
                  <div class="form-grade-group-checkbox">
                    <ng-container *ngIf="record.checkboxAvailable">
                      <switch-checkbox
                        [label]="'&nbsp'"
                        [name]="record.label"
                        [formControl]="gradeControl(record).active"
                        (clicked)="changeSuffixGrade($event, record)"
                      />
                    </ng-container>
                  </div>

                  <div class="d-inline-flex align-items-center">
                    <div class="form-grade-group-label">
                      {{ record.label }}
                    </div>

                    <div class="form-grade-group-input-container">
                      <ng-container *ngIf="!!gradeControl(record).active.value">
                        <input-number
                          class="form-grade-group-input"
                          mask="999"
                          suffix="%"
                          [prefix]="
                            gradeControl(record).prefix.value | operatorLiteral
                          "
                          [formControl]="gradeControl(record).range"
                          [readOnly]="
                            gradeTwoDisabled
                              ? i === records.length - 2 || last
                              : last
                          "
                        />
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button
          type="button"
          class="button theme-hprimarydark px-4"
          [disabled]="form.invalid"
          (click)="handleSaveClick()"
        >
          {{ "GLOBAL.SAVE" | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="currentCriteriaType === criteriaTypeEnum.PARTIAL_GRADES"
      class="pt-4"
    >
      <student-individual-grades
        [individualMarks]="criteriaConfigs"
        [disabled]="criteriaType !== criteriaTypeEnum.PARTIAL_GRADES"
        (savePartialGradesEvent)="handleEditConfigPartialGrades()"
        (updatePartialGradesEvent)="handleSetCriteriaConfigs($event)"
      />
      <div class="d-flex justify-content-center pt-4">
        <button
          type="button"
          class="button theme-hprimarydark"
          [disabled]="criteriaType !== criteriaTypeEnum.PARTIAL_GRADES"
          (click)="handleDisplayPartialGrades()"
        >
          {{ "COURSES.COURSE.RATE_COURSE" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
