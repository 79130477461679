<box>
  <div
    class="course-progress-heading d-flex align-items-center justify-content-between"
  >
    <h6 class="course-progress-heading-label">
      {{ "COURSES.COURSE.SUBJECTS" | translate | uppercase }}
    </h6>

    <div *ngIf="courseId && hasRanking">
      <round-button
        icon="trophy"
        set="uil"
        [link]="'/' + (isTeacher ? 'teacher' : 'student') + '/ranking'"
        [params]="{ filter_by: 'coursegroup', filter_pk: groupId }"
        [pure]="true"
      />
    </div>
  </div>

  <div class="progress-wrapper">
    <ul class="progress-list">
      <li class="progress-topic" *ngFor="let topic of topics; let i = index">
        <course-progress-topic
          [topic]="topic"
          [state]="{ index: i, currentTopicIndex: currentTopicIndex }"
          (topicChange)="setTopicParam($event)"
          [courseId]="courseId"
          [groupId]="groupId"
        />
      </li>
    </ul>
  </div>
</box>
