import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { StudentStatElement } from "src/app/shared/interfaces/student";

export const coursePrefix = "[Stats Group]";

const statsGroupActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const statsGroupLoad = createAction(
  statsGroupActions.request,
  props<{ organizationId: string; teacherId: string; groupId: string }>(),
);

export const statsGroupLoadSuccess = createAction(
  statsGroupActions.requestSuccess,
  props<{ stats: StudentStatElement[] }>(),
);

export const statsGroupLoadFail = createAction(
  statsGroupActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
