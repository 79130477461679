import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { RedeemModalComponent } from "src/app/shared/components/modals/redeem-modal/redeem-modal.component";
import { Member } from "src/app/shared/interfaces/member";
import { RedeemResult } from "src/app/shared/interfaces/redeem-result";
import { HelpWidgetService } from "src/app/shared/services/help-widget/help-widget.service";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { WarningModalService } from "src/app/shared/services/warning-modal/warning-modal.service";
import { Guid } from "src/app/shared/types/guid";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { AccountService } from "../../shared/services/account/account.service";
import * as fromAccount from "../actions/account.actions";
import * as fromAuth from "../actions/auth.actions";
import * as fromUI from "../actions/ui.actions";
import {
  CourseLimit,
  OrganizationLimit,
} from "src/app/shared/interfaces/limit";

@Injectable()
export class AccountEffect {
  getMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.GET_MEMBER),
      mergeMap(() =>
        this.accountService.getMember().pipe(
          mergeMap((member: Member) => [
            {
              type: fromAccount.GET_MEMBER_COMPLETED,
              payload: { member },
            },
            {
              type: fromAccount.REDIRECT,
              payload: { member },
            },
            {
              type: fromUI.STORE_LANG_SESSION_KEY,
              payload: { language: this.translate.currentLang },
            },
            {
              type: fromUI.LOAD_NOFITICATION_SETTINGS,
              payload: {
                accessMember: member.is_teacher ? "teacher" : "student",
                userId: member.id,
              },
            },
            {
              type: fromUI.RELOAD_HELP_WIDGET,
              payload: member,
            },
          ]),
          catchError(() => {
            return of({
              type: fromAccount.GET_MEMBER_FAILED,
            });
          }),
        ),
      ),
    ),
  );

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccount.REDIRECT),
        withLatestFrom(
          this.store.select((state) => state.account.redeemCode),
          this.route.queryParams,
        ),
        switchMap(
          ([action, redeemCode, queryParams]: [
            fromAccount.Redirect,
            Guid | null,
            Params,
          ]) => {
            if (!queryParams.redeemCode && !redeemCode) {
              this.store.dispatch(new fromAccount.ReleaseRedeem());
            }

            const redirect: Promise<boolean> =
              this.accountService.redirectMember(
                action.payload.member,
                queryParams.redeemCode || redeemCode,
              );

            if (redirect) {
              redirect.then(() => {
                this.checkRedeem();
              });
            } else {
              this.checkRedeem();
            }

            return of({});
          },
        ),
      ),
    { dispatch: false },
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.REGISTER),
      mergeMap((action: fromAccount.Register) =>
        this.accountService.registerStudent(action.payload).pipe(
          mergeMap(() => [
            {
              type: fromAccount.REGISTER_COMPLETED,
              payload: action.payload.redeemCode,
            },
          ]),
          catchError((httpError) => {
            return of({
              type: fromAccount.REGISTER_FAILED,
              payload: httpError.error,
            });
          }),
        ),
      ),
    ),
  );

  registerTeacher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.REGISTER_TEACHER),
      mergeMap((action: fromAccount.RegisterTeacher) =>
        this.accountService.registerTeacher(action.payload).pipe(
          mergeMap(() => [
            {
              type: fromAccount.REGISTER_COMPLETED,
              payload: action.payload.redeemCode,
            },
          ]),
          catchError((httpError) => {
            return of({
              type: fromAccount.REGISTER_FAILED,
              payload: httpError.error,
            });
          }),
        ),
      ),
    ),
  );

  registerCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccount.REGISTER_COMPLETED),
        switchMap((action: fromAccount.RegisterCompleted) => {
          this.router.navigate(["/account/login"], {
            queryParams: action.payload ? { redeemCode: action.payload } : null,
          });

          return of({});
        }),
      ),
    { dispatch: false },
  );

  changeName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.CHANGE_NAME),
      mergeMap((action: fromAccount.ChangeName) =>
        this.accountService.changeName(action.payload).pipe(
          mergeMap((member) => [
            {
              type: fromAccount.GET_MEMBER_COMPLETED,
              payload: { member },
            },
          ]),
          catchError(() => {
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.CHANGE_PASSWORD),
      mergeMap((action: fromAccount.ChangePassword) =>
        this.accountService.changePassword(action.payload).pipe(
          mergeMap(() => [
            {
              type: fromAccount.CHANGE_PASSWORD_COMPLETED,
            },
          ]),
          catchError((httpError) => {
            return of({
              type: fromAccount.CHANGE_PASSWORD_FAILED,
              payload: httpError.error,
            });
          }),
        ),
      ),
    ),
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.FORGOT_PASSWORD),
      mergeMap((action: fromAccount.ForgotPassword) =>
        this.accountService.forgotPassword(action.payload).pipe(
          mergeMap(() => [
            {
              type: fromAccount.FORGOT_PASSWORD_COMPLETED,
            },
          ]),
          catchError((httpError) => {
            return of({
              type: fromAccount.FORGOT_PASSWORD_FAILED,
              payload: httpError.error,
            });
          }),
        ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.RESET_PASSWORD),
      mergeMap((action: fromAccount.ResetPassword) =>
        this.accountService.resetPassword(action.payload).pipe(
          mergeMap(() => [
            {
              type: fromAccount.RESET_PASSWORD_COMPLETED,
            },
          ]),
          catchError((httpError) => {
            return of({
              type: fromAccount.RESET_PASSWORD_FAILED,
              payload: httpError.error,
            });
          }),
        ),
      ),
    ),
  );

  resetPasswordCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccount.RESET_PASSWORD_COMPLETED),
        switchMap(() => {
          this.router.navigate(["/account/login"]);

          return of({});
        }),
      ),
    { dispatch: false },
  );

  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.CHANGE_EMAIL),
      switchMap((action: fromAccount.ChangeEmail) =>
        this.accountService.changeEmail(action.payload).pipe(
          mergeMap(() => [
            {
              type: fromAccount.CHANGE_EMAIL_SENT,
            },
          ]),
          catchError((httpError: HttpErrorResponse) => {
            return of({
              type: fromAccount.CHANGE_EMAIL_SENT_FAILED,
              payload: httpError.error,
            });
          }),
        ),
      ),
    ),
  );

  changeEmailCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.CHANGE_EMAIL_CHECK),
      switchMap((action: fromAccount.ChangeEmailCheck) =>
        this.accountService.changeEmailCheck(action.payload).pipe(
          mergeMap(() => {
            return [
              {
                type: fromAccount.CHANGE_EMAIL_COMPLETED,
              },
              {
                type: fromAuth.LOGOUT,
              },
            ];
          }),
          catchError(() => {
            this.router.navigate(["/error/404"]);

            return of({
              type: fromAccount.CHANGE_EMAIL_FAILED,
            });
          }),
        ),
      ),
    ),
  );

  reloadHelpWidget$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUI.RELOAD_HELP_WIDGET),
        switchMap((action: fromUI.ReloadHelpWidget) => {
          this.helpWidget.setIdentify({
            name: action.payload.name,
            email: action.payload.email,
          });
          this.helpWidget.hideFields(["email"]);
          this.helpWidget.showWidget();

          return of({});
        }),
      ),
    { dispatch: false },
  );

  updateInRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.UPDATE_IN_RANKING),
      switchMap((action: fromAccount.UpdateInRanking) =>
        this.accountService.updateInRanking(action.payload).pipe(
          mergeMap((result: Member) => {
            if (result.hasOwnProperty("in_ranking")) {
              this.warningModalService.showModal({
                modalTitle: "GLOBAL.MESSAGES.INFO",
                text: "ACCOUNT.SETTINGS.ADDITIONAL.INCLUDE_IN_RANKING_MESSAGE",
                icon: "info-outline",
              });
            }

            return [
              {
                type: fromAccount.UPDATE_IN_RANKING_COMPLETED,
                payload: result,
              },
            ];
          }),
        ),
      ),
    ),
  );

  getLimits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.GET_LIMITS),
      mergeMap((action: fromAccount.GetLimits) =>
        this.accountService.getLimits(action.payload).pipe(
          switchMap((limits: Array<CourseLimit>) => [
            {
              type: fromAccount.GET_LIMITS_COMPLETED,
              payload: { type: action.payload, limits },
            },
          ]),
        ),
      ),
    ),
  );

  updateLimit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.UPDATE_LIMIT),
      mergeMap((action: fromAccount.UpdateLimit) =>
        this.accountService
          .getLimit(
            action.payload.type,
            action.payload.id,
            action.payload.organizationId,
          )
          .pipe(
            switchMap((limit: CourseLimit) => [
              {
                type: fromAccount.UPDATE_LIMIT_COMPLETED,
                payload: { type: action.payload.type, limit },
              },
            ]),
          ),
      ),
    ),
  );

  redeem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccount.REDEEM),
      tap(() => {
        this.modalService.showModal({}, RedeemModalComponent, "REDEEM_MODAL");
      }),
      switchMap((action: fromAccount.Redeem) =>
        this.accountService.redeem(action.payload).pipe(
          switchMap((result: RedeemResult) => [
            {
              type: fromAccount.REDEEM_COMPLETED,
              payload: result,
            },
          ]),
          catchError((httpError) =>
            of({
              type: fromAccount.REDEEM_FAILED,
              payload: httpError.error,
            }),
          ),
        ),
      ),
    ),
  );

  private checkRedeem(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.redeemCode) {
        this.store.dispatch(new fromAccount.Redeem(params.redeemCode));
        this.store.dispatch(new fromAccount.SetRedeemCode(null));

        this.router.navigate([], {
          queryParams: { redeemCode: null },
          relativeTo: this.route,
        });
      }
    });
  }

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private router: Router,
    private translate: TranslateService,
    private helpWidget: HelpWidgetService,
    private store: Store<{ account: fromAccountStore.AccountState }>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private warningModalService: WarningModalService,
  ) {}
}
