<div class="courses-list-element">
  <div class="row align-items-center">
    <div class="col">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <indicator *ngIf="showIndicator" />
          <span class="heading-6">{{ course.name }}</span>
        </div>

        <ng-container *ngIf="!isTeacher && group">
          <div class="col-12 col-md">
            <group-teacher-name [name]="group.teacher_name" />
          </div>
        </ng-container>

        <ng-container *ngIf="!isTeacher && group">
          <div class="col-12 col-md">
            <list-progress
              [style.width.%]="100"
              class="d-none d-md-block"
              [data]="{
                completed: group.passed_exercises,
                all: group.all_exercises
              }"
            />
          </div>

          <ng-container *ngIf="showProgressBadge">
            <div class="col-auto col-md-2">
              <span class="label badge">
                <badge icon="flow-children">
                  {{ group.passed_exercises }}/<b>{{ group.all_exercises }}</b>
                </badge>
              </span>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isTeacher">
          <div class="col">
            <div
              class="row align-items-center justify-content-start justify-content-md-center"
            >
              <div class="col-auto order-1 order-md-0">
                <badge *ngIf="course.groups.length">
                  <span class="typcn typcn-user"></span
                  >{{ course.students_count }}
                  <span class="typcn typcn-group mr-1 ml-2"></span
                  >{{ course.groups.length }}
                </badge>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="showGroupList">
          <div class="col col-md-auto order-0 order-md-1">
            <popup-menu
              *ngIf="course.groups?.length; else addGroup"
              #popup
              position="right"
            >
              <popup-trigger-label-with-chevron
                popup-trigger
                class="color-hprimarydark"
                [label]="'COURSES.COURSES.ENTER_GROUP' | translate"
                [expanded]="popup.visible"
              />

              <ng-container popup-content>
                <li simple-list-item *ngFor="let group of course.groups">
                  <simple-element class="label color-hblack">
                    {{ group.name }}
                  </simple-element>
                  <round-button
                    [link]="getGroupListLink(group.id)"
                    class="hprimarydark"
                    icon="chevron-right"
                  />
                </li>
              </ng-container>
            </popup-menu>

            <ng-template #addGroup>
              <a
                [routerLink]="['/teacher/groups/add/' + course.id]"
                [queryParams]="{ organizationId: organizationId }"
                class="label color-hsecondary"
              >
                <icon name="plus" />

                {{ "COURSES.COURSE.ADD_GROUP" | translate }}</a
              >
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="!isTeacher && !group">
      <div class="col-auto">
        <badge icon="group">
          {{ course.groups.length }}
        </badge>
      </div>
    </ng-container>

    <ng-container *ngIf="displayStats">
      <div class="ml-auto col-auto pl-5">
        <round-button
          [link]="'../courses/' + course.id + '/groups/' + group.id + '/stats'"
          tooltip="COURSES.COURSES.COURSE_STATS"
          icon="chart-line"
          class="hprimarydark"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="canShowCourseLink">
      <div class="ml-auto col-auto">
        <div class="buttons d-flex align-items-center">
          <round-button
            [link]="getCourseLink()"
            icon="chevron-right"
            class="hprimarydark"
          />
        </div>
      </div>
    </ng-container>
  </div>
</div>
