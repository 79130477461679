import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NavbarElementComponent } from "../navbar-element/navbar-element.component";
import { NavbarNavTeacherProvider } from "../../../providers/navbar-nav-teacher.provider";
import { NavbarNavStudentProvider } from "../../../providers/navbar-nav-student.provider";
import { NavbarNavAdminProvider } from "../../../providers/navbar-nav-admin.provider";
import { NavbarNavItem } from "../../../models/navbar-nav-item";
import { Guid } from "src/app/shared/types/guid";

@Component({
  selector: "navbar-nav",
  templateUrl: "./navbar-nav.component.html",
  styleUrls: ["./navbar-nav.component.scss"],
})
export class NavbarNavComponent
  extends NavbarElementComponent
  implements OnChanges
{
  @Input() isTeacher = false;
  @Input() isAdmin = false;
  @Input() activeOrganization: Guid | undefined;

  rightSideNavItems: Array<NavbarNavItem>;
  leftSideNavItems: Array<NavbarNavItem>;

  constructor(
    private readonly navbarNavTeacherProvider: NavbarNavTeacherProvider,
    private readonly navbarNavStudentProvider: NavbarNavStudentProvider,
    private readonly navbarNavAdminProvider: NavbarNavAdminProvider,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { isTeacher, isAdmin, activeOrganization } = changes;

    if (isTeacher || isAdmin || activeOrganization) {
      this.rightSideNavItems = this.configureRightSideNavItems();
      this.leftSideNavItems = this.configureLeftSideNavItems();
    }
  }

  hideMenu() {
    super.toggle();
  }

  private configureRightSideNavItems(): any {
    if (this.isAdmin) {
      return this.navbarNavAdminProvider.getRightSideItems(this.activeOrganization);
    }

    if (this.isTeacher) {
      return this.navbarNavTeacherProvider.getRightSideItems();
    }

    return this.navbarNavStudentProvider.getRightSideItems();
  }

  private configureLeftSideNavItems(): any {
    if (this.isAdmin) {
      return this.navbarNavAdminProvider.getLeftSideItems(this.activeOrganization);
    }

    if (this.isTeacher) {
      return this.navbarNavTeacherProvider.getLeftSideItems();
    }

    return this.navbarNavStudentProvider.getLeftSideItems();
  }
}
