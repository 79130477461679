import { Component, Input } from '@angular/core';
import { FlexPosition } from "../../types/flex-position";

@Component({
  selector: 'page-column-layout',
  templateUrl: './page-column-layout.component.html',
  styleUrls: ['./page-column-layout.component.scss']
})
export class PageColumnLayoutComponent {
  @Input() leftColumnWidth: string | number = 30;
  @Input() rightColumnWidth: string | number = 70;
  @Input() position: FlexPosition = 'unset';
}
