<div class="course-exercise-help">
  <div class="course-exercise-help-buttons">
    <button
      class="theme-hprimarydark help-button"
      *ngIf="
        (isTeacher && exercise.available_video) ||
        (!isTeacher && exercise.has_video)
      "
      (click)="handleVideoHelpClick()"
    >
      <span class="typcn typcn-video"></span>
      {{ "COURSES.COURSE.EXERCISE.VIDEO_HELP_BUTTON" | translate }}
    </button>

    <button
    class="theme-hprimarydark help-button"
    *ngIf="
      (isTeacher && exercise.available_help) ||
      (!isTeacher && exercise.has_help)
    "
    (click)="handleHelpClick()"
  >
    <span class="typcn typcn-globe-outline"></span>
    {{ "COURSES.COURSE.EXERCISE.HELP_BUTTON" | translate }}
  </button>
  </div>

  <div
    class="help-questions"
    *ngIf="
      currentHelpMode === 'text' &&
      isTextHelpToggled &&
      exercise.help &&
      exercise.help.length
    "
  >
    <div class="hint" *ngIf="currentHint">
      <h6 class="hint-header">{{ currentHint.question }}</h6>
      <rich-text [content]="currentHint.answer"></rich-text>
    </div>

    <ol class="hint-list">
      <li
        class="hint-element"
        *ngFor="let hint of exercise.help"
        (click)="setCurrentHint(hint)"
      >
        <span class="label hint-title">{{ hint.question }}</span>
      </li>
    </ol>
  </div>

  <div
    class="help-questions"
    *ngIf="
      currentHelpMode === 'video' &&
      isVideoHelpToggled &&
      exercise.video_help &&
      exercise.video_help.length
    "
  >
    <div
      class="hint"
      *ngIf="currentVideoHint"
      [ngSwitch]="currentVideoHint.player"
    >
      <rich-text
        *ngSwitchCase="hintPlayer.YOUTUBE"
        [content]="currentVideoHint.html"
      ></rich-text>
      <custom-video-player
        *ngSwitchCase="hintPlayer.CLOUDFRONT"
        [src]="currentVideoHint.url"
      ></custom-video-player>
    </div>

    <ol class="hint-list">
      <li
        class="hint-element"
        *ngFor="let hint of exercise.video_help; let i = index"
        (click)="setCurrentVideoHint(hint)"
      >
        <span class="label hint-title">{{ hint.name }}</span>
      </li>
    </ol>
  </div>
</div>
