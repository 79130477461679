<div class="video-responsive">
  <video
    #player
    class="video-js vjs-big-play-centered"
    controls
    muted
    playsinline
    autoplay
    preload="none">
  </video>
</div>
