<div class="course-exercise-answer-history-popup-menu">
  <button
    cdkOverlayOrigin
    [tooltip]="'COURSES.COURSE.EXERCISE.HISTORY.BUTTON_TOOLTIP' | translate"
    class="theme-hprimarydark"
    type="button"
    #trigger="cdkOverlayOrigin"
    #triggerButton
    (click)="open()"
  >
    <icon set="uil" name="history" />
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPositions]="popupPositions"
    (overlayOutsideClick)="close($event)"
  >
    <ul
      *ngIf="!isEmpty; else emptyTemplate"
      class="course-exercise-answer-history-popup-menu-body"
    >
      <li
        *ngFor="let answer of answers"
        class="course-exercise-answer-history-popup-menu-item">
        <div
          class="course-exercise-answer-history-popup-menu-item-container"
        >
          <badge
            set="uil"
            icon="download-alt"
            theme="hprimary-700"
            class="course-exercise-answer-history-popup-menu-item-badge"
            [reversed]="true"
            (click)="handleDownload(answer)"
          >
            <span
              class="course-exercise-answer-history-popup-menu-item-badge-label"
            >
              {{ answer.filename }}
            </span>
          </badge>

          <div
            class="course-exercise-answer-history-popup-menu-item-date-label"
          >
            {{ answer.uploaded_at | date }}
          </div>

          <answer-status [status]="answer.review" />
        </div>
      </li>
    </ul>
  </ng-template>

  <ng-template #emptyTemplate>
    <ul class="course-exercise-answer-history-popup-menu-body">
      <li class="course-exercise-answer-history-popup-menu-item">
        <span>
          {{
            "COURSES.COURSE.EXERCISE.HISTORY.NO_HISTORY_TO_DISPLAY"
              | translate
          }}
        </span>
      </li>
    </ul>
  </ng-template>
</div>
