import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  invitedTeachersListLoad,
  invitedTeachersListLoadFail,
  invitedTeachersListLoadSuccess,
} from "../../actions/admin/invited-teachers.actions";
import { InvitedTeachers } from "src/app/shared/interfaces/orgadmin/invited-teacher";
import {
  sendInviteTeacher,
  sendInviteTeacherFail,
} from "../../actions/admin/send-invite-teacher.actions";
import {
  singleActiveTeacherLoad,
  singleActiveTeacherLoadSuccess,
  singleActiveTeacherLoadFail,
} from "../../actions/admin/single-active-teacher.actions";
import { TeacherDetails } from "src/app/shared/interfaces/orgadmin/course-teachers";
import {
  deleteInvitationTeacherLoad,
  deleteInvitationTeacherLoadFail,
  deleteInvitationTeacherLoadSuccess,
} from "../../actions/admin/delete-invitation-teacher.action";

@Injectable()
export class InvitedTeachersEffect {
  getInivitedTeachersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitedTeachersListLoad),
      switchMap((param) => {
        return this.adminService.getInvitedTeachers(param.id).pipe(
          map((res: InvitedTeachers) =>
            invitedTeachersListLoadSuccess({ invitedTeachers: res }),
          ),
          catchError(({ error }) => of(invitedTeachersListLoadFail(error))),
        );
      }),
    ),
  );

  sendInviteTeacherList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendInviteTeacher),
      switchMap((param) => {
        return this.adminService
          .sendInviteTeacher(param.organizationId, param.payload)
          .pipe(
            map(() =>
              invitedTeachersListLoad({
                id: param.organizationId,
                active: "False",
              }),
            ),
            catchError(({ error }) => {
              return of(sendInviteTeacherFail({ error }));
            }),
          );
      }),
    ),
  );

  getSingleActiveTeacher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(singleActiveTeacherLoad),
      switchMap((param) => {
        if (!param.organizationId) return EMPTY;
        return this.adminService
          .getActiveTeacher(param.organizationId, param.teacherId)
          .pipe(
            map((res: TeacherDetails) =>
              singleActiveTeacherLoadSuccess({ activeTeacher: res }),
            ),
            catchError(({ error }) => of(singleActiveTeacherLoadFail(error))),
          );
      }),
    ),
  );

  deleteInvitationTeacher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInvitationTeacherLoad),
      switchMap((param) => {
        return this.adminService
          .deleteInvitationTeacher(param.organizationId, param.invitationId)
          .pipe(
            map(() => deleteInvitationTeacherLoadSuccess()),
            catchError(({ error }) =>
              of(deleteInvitationTeacherLoadFail(error)),
            ),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
