<div class="course-topic">
  <course-topic-header [currentTopic]="currentTopic" />

  <course-topic-exercises
    [section]="null"
    [teacher]="isTeacher"
    [courseId]="courseId"
    [groupId]="activeGroup"
    [currentTopic]="currentTopic"
    [exercises]="exercises"
    [emitters]="{
      startExercise: startExercise,
      stopExercise: stopExercise,
      getHelp: getHelp,
      getVideoHelp: getVideoHelp,
      answer: answer
    }"
  />

  <ng-container *ngIf="!isIndividualListEmpty">
    <course-topic-exercises
      headerLabel="COURSES.COURSE.EXERCISES_FOR_INDIVIDUAL_WORK"
      class="course-topic-exercises-individual"
      [subtitleLabel]="individualSubtitleLabel"
      [section]="ExerciseSection.INDIVIDUAL"
      [teacher]="isTeacher"
      [courseId]="courseId"
      [groupId]="activeGroup"
      [currentTopic]="currentTopic"
      [exercises]="individualExercises"
      [emitters]="{
        startExercise: startExercise,
        stopExercise: stopExercise,
        getHelp: getHelp,
        getVideoHelp: getVideoHelp,
        answer: answer
      }"
    />
  </ng-container>
</div>
