import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  statsGroupLoad,
  statsGroupLoadFail,
  statsGroupLoadSuccess,
} from "../../actions/admin/stats-group.actions";
import { Guid } from "src/app/shared/types/guid";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Injectable()
export class StatsGroupEffect {
  getStatsGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statsGroupLoad),
      switchMap((param) => {
        return this.adminService
          .getStatsGroup(param.organizationId, param.teacherId, param.groupId)
          .pipe(
            map((stats) => {
              return Object.keys(stats)
                .map((studentId: Guid) => ({
                  id: studentId,
                  ...stats[studentId],
                }))
                .map((stat) => {
                  return {
                    ...stat,
                    topics: Object.keys(stat.topics).map((topicId: Guid) => {
                      return {
                        id: topicId,
                        ...stat.topics[topicId],
                        exercises: Object.keys(
                          stat.topics[topicId].exercises,
                        ).map((exerciseId: Guid) => {
                          return {
                            id: exerciseId,
                            ...stat.topics[topicId].exercises[exerciseId],
                          };
                        }),
                      };
                    }),
                  };
                });
            }),
            map((stats: StudentStatElement[]) =>
              statsGroupLoadSuccess({ stats }),
            ),
            catchError(({ error }) => of(statsGroupLoadFail(error))),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
